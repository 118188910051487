<template>
  <div>
    <a-row>
      <a-col :span="24">
        <a-form
          :form="form"
          :label-col="{ span: 8}"
          :wrapper-col="{ span: 16 }"
          @submit="handleSubmit"
        >
          <span class="title">
            基本信息
          </span>
          <a-form-item label="用户账号">
            {{ info.user_name }}
          </a-form-item>
          <a-form-item label="用户昵称">
            {{ info.nick_name }}
          </a-form-item>
          <a-form-item label="上传时间">
            {{ formatTime(info.created_at) }}
          </a-form-item>
          <a-form-item label="状态">
            {{ info.status }}
          </a-form-item>
          <a-form-item label="上传类型">
            {{ info.record_type }}
          </a-form-item>
          <a-form-item label="名称">
            {{ info.name }}
          </a-form-item>
          <a-form-item label="文件">
            {{ info.file_name }}
          </a-form-item>
          <a-form-item label="大小">
            {{ info.file_size | bToMbGb }}
          </a-form-item>
          <a-form-item label="格式">
            {{ info.file_extension }}
          </a-form-item>
          <a-form-item label="宣传图">
            <a v-if="posterUrl" @click="handlePreview">点击查看</a>
          </a-form-item>
          <a-form-item label="标签">
            {{ info.label }}
          </a-form-item>

          <a-divider />

          <span class="title">
            审核
          </span>

          <a-form-item label="审核结果">
            <a-radio-group
              v-decorator="['status',{
                initialValue: 'passed'
              }]"
              @change="handleChangeIsPass"
            >
              <a-radio value="passed">
                通过
              </a-radio>
              <a-radio value="not_pass">
                未通过
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <div v-show="isPass">
            <a-form-item label="激励积分">
              <a-input-number
                :min="1"
                :max="10000000"
                :precision="0"
                :step="1"
                style="width: 100%"
                v-decorator="['balance', {
                  initialValue: 5,
                  rules: [
                    { required: true, message: '请输入激励积分' },
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="文档名称">
              <a-input
                v-decorator="['name', {
                  rules: [
                    { required: true, message: '请输入文档名称' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="宣传图">
            <div>支持图片格式：PNG、JPG，10M以内，推荐图标尺寸210mm*297px</div>
              <a-upload
                accept=".png,.jpg"
                :file-list="posterFileList"
                :remove="handleRemovePoster"
                :before-upload="handleBeforeUploadPoster"
                :custom-request="handleUploadPoster"
              >
                <a-icon type="loading" v-if="posterloading" />
                  <a v-if="posterFileList.length === 0">
                    点击上传
                  </a>
              </a-upload>
            </a-form-item>

            <a-form-item label="文档授权">
              <a-radio-group
                v-decorator="['is_free',{
                  initialValue: false,
                  rules: [
                    { required: true, message: '请选择文档授权' },
                  ]
                }]"
                @change="handleChangeIsFree"
              >
                <a-radio :value="false">
                  收费
                </a-radio>
                <a-radio :value="true">
                  免费
                </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="价格(积分)" v-if="!isFree">
              <a-input-number
                :min="1"
                :max="10000000"
                :precision="0"
                :step="1"
                style="width: 100%"
                v-decorator="['point', {
                  rules: [
                    { required: !isFree && isPass, message: '请输入价格(积分)' },
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="推荐">
              <a-checkbox
                v-decorator="['is_recommend',{
                  valuePropName: 'checked',
                  initialValue: false
                }]"
              />
            </a-form-item>

            <a-form-item label="显示">
              <a-checkbox
                v-decorator="['visible',{
                  valuePropName: 'checked',
                  initialValue: true
                }]"
              />
            </a-form-item>

            <a-form-item label="一级分类">
              <a-select
                v-decorator="['first_category_id']"
                placeholder="请选择一级分类"
                showSearch
                allow-clear
                :filter-option="filterOption"
                :loading="loadingFirstCateOptions"
                @focus="fetchFirstCateOptions"
                @change="handleFirstCateChange"
              >
                <a-select-option
                  v-for="item in firstCateOptions"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="二级分类">
              <a-select
                v-decorator="['second_category_id']"
                placeholder="请选择二级分类"
                allow-clear
                showSearch
                :filter-option="filterOption"
                :loading="loadingSecondCateOptions"
                @change="handleSecondCateChange"
              >
                <a-select-option
                  v-for="item in secondCateOptions"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

              <a-form-item label="三级分类">
              <a-select
                v-decorator="['third_category_id']"
                placeholder="请选择三级分类"
                showSearch
                :filter-option="filterOption"
                allow-clear
                :loading="loadingThirdCateOptions"
              >
                <a-select-option
                  v-for="item in thirdCateOptions"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="标签">
              <a-textarea
                v-decorator="['label']"
                placeholder="请为文档打上标签，每个标签不超过5个字，标签之间用顿号隔开，便签数量不超过10个"
                autocomplete="off"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-item>
          </div>
          
          <div v-show="!isPass">
            <a-form-item label="原因">
              <a-textarea
                v-decorator="['reason']"
                placeholder="请输入未通过原因"
                autocomplete="off"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-item>
          </div>

          <a-form-item label="" class="sfm-flex sfm-justify-center sfm-items-center">
            <a-button type="primary" @click="preview">
              预览文档
            </a-button>
            <a-button type="primary" @click="download" class="search-button">
              下载文档
            </a-button>
            <a-button type="primary" html-type="submit" class="search-button">
              确定
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>

    <preview-image
      :visible.sync="previewVisible"
      :image="posterUrl"
    />
  </div>
</template>

<script>
import formatTime from '@/utils/formatTime'
import { DocumentCategoryModel } from "@/models/document_category.js"
const documentCategoryModel = new DocumentCategoryModel()
import upload from "@/utils/oss";
import { ossDownloadFile } from "@/utils/oss";
import { UserUploadRecordModel } from '@/models/user_upload_record.js'
const userUploadRecordModel = new UserUploadRecordModel()
import { FileChecksum } from "@/utils/fileChecksum";

export default {
  name: 'ApprovalDocument',
  components: {
    PreviewImage: () => import('@/components/PreviewImage'),
  },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'approval_document' }),
      formatTime,
      posterloading: false,
      submitting: false,
      loading: true,
      isFree: false,
      isPass: true,
      previewVisible: false,
      firstCateOptions: [],
      secondCateOptions: [],
      thirdCateOptions: [],
      loadingFirstCateOptions: false,
      loadingSecondCateOptions: false,
      loadingThirdCateOptions: false,
      searchingRootId: 0,
      maxPosterSize: 10 * 1024 * 1024,
      posterFileList: [],
      posterKey: '',
      posterSize: 0,
      posterType: '',
      posterName: '',
      posterChecksum: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    handleRemovePoster() {
      this.posterFileList = []
      this.posterKey = ''
      this.posterSize = undefined
      this.posterType = ''
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleChangeIsFree(e) {
      this.isFree = e.target.value
    },

    handleChangeIsPass(e) {
      this.isPass = e.target.value === 'passed'
    },

    handleBeforeUploadPoster(file) {
      if (file.size > this.maxPosterSize) {
        this.$notification.error({
          message: '提示',
          description: `文件大小不能超过 ${this.maxPosterSize / 1024.0 / 1024.0} MB`,
          duration: 2
        })
        file.status = 'error'
        return false
      }
    },

    handleUploadPoster(options) {
      this.posterloading = true
      const { file } = options
      const fileChecksum = new FileChecksum(file)
      fileChecksum.create((error, checksum) => {
        upload(file, "softmall-documents", {}).then((key) => {
          if (key) {
            this.posterKey = key
            this.posterSize = file.size
            this.posterType = file.type
            this.posterName = file.name
            this.posterChecksum = checksum
            this.posterFileList.push(file)
            this.posterloading = false
          }
        })
      })
    },

    fetchFirstCateOptions() {
      if (this.firstCateOptions.length === 0) {
        this.loadingFirstCateOptions = true
        const data = {
          root_id: 0,
          parent_id: 0
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.firstCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingFirstCateOptions = false
        })
      }
    },

    handleFirstCateChange(firstCateId) {
      this.secondCateOptions = []
      this.thirdCateOptions = []
      this.searchingRootId = 0

      this.form.setFieldsValue({
        second_category_id: undefined,
        third_category_id: undefined
      })

      if (firstCateId) {
        this.searchingRootId = firstCateId
        this.fetchSecondCateOptions({ root_id: firstCateId, parent_id: firstCateId })
      }
    },

    handleSecondCateChange(secondCateId) {
      this.thirdCateOptions = []

      this.form.setFieldsValue({
        third_category_id: undefined
      })

      if (secondCateId) {
        this.fetchThirdCateOptions({ root_id: this.searchingRootId, parent_id: secondCateId })
      }
    },

    fetchSecondCateOptions(params) {
      if (this.secondCateOptions.length === 0) {
        this.loadingSecondCateOptions = true
        const data = {
          root_id: params.root_id,
          parent_id: params.parent_id
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.secondCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingSecondCateOptions = false
        })
      }
    },

    fetchThirdCateOptions(params) {
      if (this.thirdCateOptions.length === 0) {
        this.loadingThirdCateOptions = true
        const data = {
          root_id: params.root_id,
          parent_id: params.parent_id
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.thirdCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingThirdCateOptions = false
        })
      }
    },

    download() {
      ossDownloadFile("softmall-documents", this.info.file_key, this.info.file_name)
    },

    handlePreview() {
      this.previewVisible = true
    },

    fetchData() {
      this.loading = true
      if (this.info.first_category_id > 0) {
        this.fetchFirstCateOptions()
      }

      if (this.info.second_category_id > 0) {
        this.fetchSecondCateOptions({ root_id: this.info.first_category_id, parent_id: this.info.first_category_id })
      }

      if (this.info.third_category_id > 0) {
        this.fetchThirdCateOptions({ root_id: this.info.first_category_id, parent_id: this.info.second_category_id })
      }

      this.isFree = this.info.is_free
      this.posterKey = this.info.poster_key
      if (this.info.poster_key) {
        this.posterFileList.push({
          uid: this.info.poster_key,
          name: this.info.poster_name,
          status: 'done',
        })
        this.posterUrl = this.info.poster_url
      }
      this.form.setFieldsValue(this.info)

      if (this.info.status_slug === 'checking') {
        this.form.setFieldsValue({
          status: 'passed'
        })
        this.isPass = true
      } else {
        this.form.setFieldsValue({
          status: this.info.status_slug
        })
        this.isPass = this.info.status_slug === 'passed'
      }
      
      if (this.info.document_name) {
        this.form.setFieldsValue({
          name: this.info.document_name
        })
      }

      if (this.info.document_label) {
        this.form.setFieldsValue({
          label: this.info.document_label
        })
      }
      this.loading = false
    },

    fetchRecordData() {
      this.loading = true
      userUploadRecordModel.show(this.info.id).then(res => {
        if(res.code === 0) {
          this.info = res.result
          this.fetchData()
        }
        this.loading = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const params = {
            ...values,
            poster_blob: {
              key: this.posterKey,
              filename: this.posterName,
              byte_size: this.posterSize,
              content_type: this.posterType,
              checksum: this.posterChecksum,
            }
          }
          userUploadRecordModel.update(params, this.info.id).then((res) => {
            if (res.code === 0) {
              this.$notification.open({
                message: '提示',
                description: res.message,
                duration: 2
              })
              this.$router.push({ path: '/task/user_upload_records' })
            }
            this.submitting = false
          })
        }
      })
    },

    // 预览
    preview() {
      this.$router.push({
        path: `/task/user_upload_records/${this.info.id}/preview`
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.title {
  margin-left: 95px;
  font-weight: bold;
}

.search-button {
  margin-left: 5px;
}

.ant-form-item {
  margin-bottom: 5px;
}
</style>
